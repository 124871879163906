import React from 'react';
import { Card, Col, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import DynamicIcon from '../components/DynamicIcon';

import utils from '../utils';

function TenantAppBox({ tenantApp, tenant, preview=false }) {

    const substringSubtitle = (subtitle) => {
        let subtitleInfo = subtitle.substring(0, 95) 
        if (subtitleInfo && subtitleInfo.length > 94) {
            subtitleInfo = subtitleInfo + '...'
        }
        return subtitleInfo;
    }

    return (
        <Col className="app-card-col" >
                <LinkContainer to={tenantApp.fields.customctalink ? tenantApp.fields.customctalink : `/${tenantApp.fields.apptype}`}>
            <Card className={"app-card " + tenantApp.fields.apptype +' weird-corners text-center' + (preview ? ' preview ' : '')}>
            {
                preview ? 
                    tenantApp.fields.previewImage && tenantApp.fields.previewImage.url ? 
                        <div className='preview-img-div'>
                            <Image className='preview-card-img' src={tenantApp.fields.previewImage.url}/> 
                        </div>
                    :  
                    <div className='preview-img-div' style={{background: 'grey'}}></div>
                : null
            }
                <div className="tenant-header">
                <Card.Title className="tenant-app-name align-left p-i-03 m-b-end-04 h2">
                    <DynamicIcon className='txt-primary svg-24 m-i-end-03' iconName={tenantApp.fields.iconname} />
                    <h2 className='display-inline vertical-align '>
                        { tenantApp.fields.appAlternateTitle ?  tenantApp.fields.appAlternateTitle : tenantApp.fields.apptype ? utils.getAppName(tenantApp.fields.apptype) : null } 
                    </h2>
                </Card.Title>
                <Card.Subtitle className="text-muted align-left p-b-02 p-i-03 m-i-start-07"><span className='p-i-start-01'>{ tenantApp.fields.description ? substringSubtitle(tenantApp.fields.description) : null }</span></Card.Subtitle>
                </div>
                {/* Leaving for now - May add button back in  */}
                {/* <div className='align-right m-i-end-06'>
                        <Button className='m-b-end-06 btn-brand-primary small-btn-padding'>
                            { tenantApp.fields.ctatext ? tenantApp.fields.ctatext : 'Go' }
                        </Button>
                </div> */}
            </Card>
                    </LinkContainer>
        </Col>
    )
}

export default TenantAppBox
