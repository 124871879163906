import {
  BrowserRouter as Router
} from "react-router-dom";
import * as msal from "@azure/msal-browser";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { LastLocationProvider } from 'react-router-last-location';
import { useEffect } from 'react';

import MainPage from "./pages/MainPage";
import utils from './utils';

import './styles/main.scss';
import SignedOutPage from "./pages/SignedOutPage";

function App() {

  //this enables us to set the urls in AAD application and it will allow any page to auth based on that list
  //let locationStr = window.location.href;

  // if(!locationStr.endsWith('/')) {
  //   locationStr =+ '/';
  // }

  const msalConfig = {
    auth: {
      clientId: '236c33de-553c-4b1a-8965-7e4c148cc57a',
      redirectUri: `${window.location.origin}/blank.html`,
      authority: 'https://login.microsoftonline.com/08da5958-7890-484c-b92c-ba495b4218bd',
      instanceAware: true
    },
    cache: {
      cacheLocation: "localStorage",//keeps login token across sites
    },
    system: {
      loggerOptions: {
        logLevel: msal.LogLevel.Warning,
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }

          if(process.env.REACT_APP_ENV === "PROD") {
            fetch(`${process.env.REACT_APP_WEB_API}/api/logs/auth-error`, {
              method: 'POST',
              body: JSON.stringify(message)
            });
          }
            
          return;
        },
        piiLoggingEnabled: false
      }
    }
  };

  let msalInstance = null;
  msalInstance = new msal.PublicClientApplication(msalConfig);

  useEffect(() => {
    utils.initializeTracking();
  });

  return (
    <MsalProvider instance={msalInstance}>
      <Router>
        <LastLocationProvider>
          <div className="App">
            <AuthenticatedTemplate>
              <MainPage />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <SignedOutPage />
            </UnauthenticatedTemplate>
          </div>
        </LastLocationProvider>
      </Router>
    </MsalProvider>
  );
}

export default App;
