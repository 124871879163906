import React, {useState, useEffect} from 'react';
import {Row} from 'react-bootstrap';
import CONSTANTS from '../../constants';
import FieldDisplay from '../FieldDisplay';
import { Route, useRouteMatch, Switch, Redirect } from 'react-router-dom';
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import utils  from '../../utils';
import UrlDrivenTopNav from '../Layout/Nav/UrlDrivenTopNav';

function VaccineMarketsDisplay({ tenant, content, navs, tntFor=null }) {

  const { path } = useRouteMatch();
  let useNavs = utils.cleanUpNavs(navs, content);
  let nameOfApp = utils.findURLPath(tenant.tenant.fields.apps, "vaccine_markets");
 
  const [ activeTab, setActiveTab ] = useState({title: 'Overview', url: 'overview', level_three: null});
  const [ loading, setLoading ] = useState(false);

  const getAppPath = (content, tntFor) => {
    if(tntFor) {
      return `vaccine_markets/${content.fields.market_slug}::${tntFor}`;
    } else {
      return `vaccine_markets/${content.fields.market_slug}`;
    }
  }


  const hasCustomCTA = (tenant) => {
    const tenantVMAppArr = tenant.tenant.fields.apps.filter( (thisApp) => {
      return thisApp.fields.apptype === CONSTANTS.APPS_BY_APPTYPE.VACCINE_MARKETS;
    });

    if(tenantVMAppArr.length > 0) {
      return tenantVMAppArr[0].fields.customctalink && tenantVMAppArr[0].fields.customctalink.length > 0;
    } else {
      return false;
    }
  }

  //if this app is called Vaccine Almanac, then we need to use 'Vaccine Almanac' for marketName
  const getTitleForMarket = (tenant, marketName) => {
    const tenantVMAppArr = tenant.tenant.fields.apps.filter( (thisApp) => {
      return thisApp.fields.apptype === CONSTANTS.APPS_BY_APPTYPE.VACCINE_MARKETS;
    });

    if(tenantVMAppArr.length > 0) {
      if(tenantVMAppArr[0].fields.appAlternateTitle && tenantVMAppArr[0].fields.appAlternateTitle.length > 0) {
        return tenantVMAppArr[0].fields.appAlternateTitle;
      }
    }

    return marketName;

  }


  const hasCustomCTALinkName = (tenant) => {
    let temp = tenant.tenant.fields.apps.filter((thisApp) => {
      return thisApp.fields.customCTALinkName;
    })

    if(temp.length > 0) {
      return temp;
    }
  }

  let hasCustom = hasCustomCTA(tenant);
  let hasCustomCTAName = hasCustomCTALinkName(tenant)

  const returnItemContent = () => {
    let returnItem;

    if (hasCustomCTAName && hasCustomCTAName.length > 0) {
      returnItem = {marketName: hasCustomCTAName[0].fields.customCTALinkName};
    } else {
      returnItem = content ? content : null
    }
    return returnItem;
  }

    useEffect(()=>{
        setLoading(false)
    },[loading])

  //If there is a third level nav it needs selection prop leave so doesn't break other apps
  const handleNavSelection = (selection=null, url=null, title=null) => {
    setLoading(true)
    setActiveTab({title: title, url: url, level_three: selection})
  }



  return (
    content && tenant ?
      <>
        <BreadCrumbBar 
          appName={nameOfApp} 
          item={returnItemContent()} 
          displayFieldName="marketName" 
          noListLink={hasCustom}/>

        <div className="market-analysis-display">
        <h1 className='h1'>{ getTitleForMarket(tenant, content.fields.marketName) }</h1>
        <UrlDrivenTopNav
          apppath={getAppPath(content, tntFor)}
          navs={utils.filterOutUnneeded(useNavs)} 
          subItems={true} 
          fields={null}
          activeTab={activeTab}
          sendNavItemToShow={handleNavSelection}

          />
        <Row>
            <Switch>
              <Route exact path={`${path}/:infoID`}>
                  <FieldDisplay 
                    fields={content} 
                    fieldsDescr={navs} 
                    infoToShow={utils.getDefaultView(utils.filterOutUnneeded(useNavs), "overview") }
                    tenant={tenant} />
              </Route>
              <Redirect exact from={`${path}/`} to={`${path}/${utils.getDefaultView(utils.filterOutUnneeded(useNavs), "overview")}`} />
            </Switch>
        </Row>
        </div>
      </>
    : null
  )
}
export default VaccineMarketsDisplay;