import React from 'react';
import { Filter, Target } from '@pyramid-embed/embed-react';
import { useParams } from 'react-router';
import PyramidFieldVisual from './PyramidFIeldVisual';
import PBIEmbeddedViz from './PBIEmbeddedViz';
import PBIEmbedClassDriven from './PBIEmbedClassDriven';
import { models } from 'powerbi-client';



import utils from '../utils';
import LeadershipListDisplay from './CompanyProfiles/LeadershipListDisplay';
import FilteredPagedNewsPage from './News/FilteredPagedNewsList';
import KeyEventsDisplay from './PartnerProfiles/KeyEventsDisplay';
import CompareCompTabs from './IndustryAnalysis/CompareCompTabs';
import BasicElasticEngine from './BasicElasticEngine';
import ircUtils from './IRC/ircUtils';
import ListSkeletonLoader from './Layout/ListSkeletonLoader';
import CountryNewsWrapper from './IRC/CountryNewsWrapper';

import CONSTANTS from '../constants';



function FieldDisplay({ fields, fieldsDescr, infoToShow=null, tenant=null, isForCountry=null, levelThreeNav=null, loading=false}) {

    const DEFAULT_PY_HEIGHT = '500px';
    const DEFAULT_VIZ_HEIGHT = 500;
    const DEFAULT_PY_WIDTH = '100%';

    let { infoID } = useParams();

    if (!infoID) {
        infoID = infoToShow;
    }

    const convertNewsListToString = (newsList) => {
        let accumeArray = [];
        newsList.forEach((item) => {
            accumeArray.push(item.fields.tagtext)
        })
        return accumeArray.join(',')
    }

    const filterOutPageItemsThatHaveNoContent = (pageitems, fields) => {

        if(pageitems && pageitems.length > 0){
            return pageitems.filter( (thisPI) => {
                return fields.hasOwnProperty(thisPI.fieldAPIName) && fields[thisPI.fieldAPIName] !== '' && fields[thisPI.fieldAPIName] !== null;
            });
        } 

        return [];
    }

    const getFieldNameFromFilterStr = (fieldName) => {
        
        if(!fieldName || fieldName.length < 1 ) {
            return undefined;
        }
        
        let thefieldItems = fieldName.split('**');

        if(thefieldItems.length > 1) {
            return thefieldItems[1];
        }

        return undefined;
    }

    const replaceFieldValueFromName = (fieldsDisplayed, fieldName) => {
        //only continue if field exists in fields list with the appropriate field name

        //create a map of lowerCase field names to the actual field name
        let fields = fieldsDisplayed.fields;
        let fieldsList = Object.keys(fields).reduce( (acc, thisField) => {
            acc[thisField.toLowerCase()] = thisField;
            return acc;
        }, {});

        //fieldsList is now an object like this:
        // { 
        //  'fieldname1': 'fieldName1',
        //  'mysuperlongfield': 'mySuperLongField'
        //}

        //if the field name is not in the list, return empty string
        if(fieldName.length < 1 || !(fieldsList.hasOwnProperty(fieldName.toLowerCase()))) {
            return '';
        }

        return fields[fieldsList[fieldName.toLowerCase()]];

    }

    const hasMultiValues = (fieldDisplayed, fieldName) => {

        let theFieldName = getFieldNameFromFilterStr(fieldName);

        if (theFieldName) {
            return (fieldDisplayed.fields[theFieldName].split(';').length > 1);
        }

        return false;
        
    }

    const getMultiFilterTargetObj = (fieldsList, fieldApiName, pyramidFilterString) => {
        //first, strip values out of the field

        let theFieldName = getFieldNameFromFilterStr(pyramidFilterString);

        let myValues = fieldsList.fields[theFieldName].split(";");

        let myTarget = Target.create();
        let filtersSet = Filter.create();

        myValues.forEach( (thisValue) => {
            let thefieldItems = pyramidFilterString.split('**');
            thefieldItems[1] = thisValue;
            let theFixedField = thefieldItems.join('');
            filtersSet.addUniqueName(theFixedField);
        })

        myTarget.add(filtersSet);

        return myTarget
    }

    const getFiltersForPyramid = (fieldStr, fieldsToUse) => {
        //returns an array for a pyramid viz based on the meta field pyramidFilterString

        if(!fieldStr || fieldStr.length < 1) {
            return [];
        }

        //first, split into array on semi-colons
        let arrayOfAllFilters = fieldStr.split(';');

        //replace the **[fieldDeclaration]** in each
        let returnedArray = arrayOfAllFilters.map( (thisItem) => {
            let thefieldItems = thisItem.split('**');
            if(thefieldItems.length > 1){
                thefieldItems[1] = replaceFieldValueFromName(fieldsToUse,thefieldItems[1]);
            }
            return thefieldItems.join('');
        });

        return returnedArray;

    }

    const comparison = ( field, comparator, valueTarget) => {
        switch (comparator) {
            case 'not_equal' :
                return field !== valueTarget;
            case 'contains':
                return (field.indexOf(valueTarget) > -1);
            default:
                //equals
                return field === valueTarget;
        }
    }

    const filterForApplicableOverrides = (overrideArray, contentFields, tenant) => {

        //first, check tenant on override, if not set then is universal

        let usedOverrides = overrideArray.filter( (thisOvr) => {
            return !thisOvr.fields.hasOwnProperty('specificToTenant_TextField') || 
                    thisOvr.fields.specificToTenant_TextField.toLowerCase() === tenant.tenant.fields.tenantslug.toLowerCase();
        })

        //then check to see if matches this particular item

        usedOverrides = usedOverrides.filter( (thisOverride) => {
            return comparison(contentFields.fields[thisOverride.fields.conditionFieldName], thisOverride.fields.conditionOperator, thisOverride.fields.conditionValue);
        })

        return usedOverrides;
    }

    let overridePyramidHeight = undefined;
    let overridePyramidWidth = undefined;

    const getEmbedOptionsForItem = (item, fields, tenant) => {
        let retOptions = {};
        let filtersToSet;
        let filterPreArray;
        let pyramidStrToUse;

        let getContentIDToGet;

        if(item.masterContentID && item.masterContentID !== ''){
            getContentIDToGet = item.masterContentID;
        } else {
            getContentIDToGet = fields.fields[item.fieldAPIName];
        }

        //see if there are pyramid field overrides
        let overrides = item.overrides && item.overrides.length > 0 ? item.overrides : null;

        //these overrule if they applicable - even overrules on existing filterstring (null if clear it out)
        if(overrides) {
            let functionalOverrides = filterForApplicableOverrides(overrides, fields, tenant);
            if(functionalOverrides.length > 0){
                functionalOverrides.forEach( (thisFuncOverride) => {
                    if(thisFuncOverride.fields.pyramidContentIDtoUse){
                        getContentIDToGet = thisFuncOverride.fields.pyramidContentIDtoUse;
    
                        //force filter string, even if null
                        if (thisFuncOverride.fields.filterString) {
                            pyramidStrToUse = thisFuncOverride.fields.filterString !== 'null' ? thisFuncOverride.fields.filterString : '';
                        }
                    }

                    if(thisFuncOverride.fields.customPyramidHeightinPixels) {
                        overridePyramidHeight = thisFuncOverride.fields.customPyramidHeightinPixels + 'px';
                    }

                    if(thisFuncOverride.fields.customPyramidWidthinPercent) {
                        overridePyramidWidth = thisFuncOverride.fields.customPyramidWidthinPercent + '%';
                    }


                });
            } else {
                pyramidStrToUse = item.pyramidFilterString ? item.pyramidFilterString : '';
            }
            
        } else {
            pyramidStrToUse = item.pyramidFilterString ? item.pyramidFilterString : '';
        }

        retOptions.contentId = getContentIDToGet;

        if(hasMultiValues(fields, pyramidStrToUse)) {
            retOptions.targets = getMultiFilterTargetObj(fields, item.fieldAPIName, pyramidStrToUse);
        } else {
            filterPreArray = getFiltersForPyramid(pyramidStrToUse, fields);

            if(filterPreArray && filterPreArray.length > 0) {
                //only set the filters if there are some specified.

                filtersToSet = Filter.create();

                filterPreArray.forEach( (thisfilter) => {
                    filtersToSet.addUniqueName(thisfilter);
                });

                retOptions.filters = filtersToSet;
            }
        }

        return retOptions;
    }

    const replaceTokens = (arrayItem, fields) => {
        //first, split into array on semi-colons
        if(arrayItem.indexOf('**') > -1) {
            let thefieldItems = arrayItem.split('**');
            if(thefieldItems.length > 1){
                return replaceFieldValueFromName(fields,thefieldItems[1]);
            }
        }

        return arrayItem;
    }

    const replaceFieldRefWithValue = (fieldStr, fields) => {
        //returns an array for a pyramid viz based on the meta field pyramidFilterString

        
        if(!fieldStr || fieldStr.length < 1) {
            return '';
        }

        //first, if there are multiples, split on semi-colons
        if(fieldStr.indexOf(';') > -1) {
            let multipleItems = fieldStr.split(';');
            if(multipleItems.length > 1) {
                return multipleItems.map( (thisItem) => {
                    return replaceTokens(thisItem.trim(), fields);
                })
            }
            
            
        }



        return [replaceTokens(fieldStr, fields)];
    }

    const formatForProperType = (fieldValueArray, fieldType=null) => {

        return fieldValueArray.map( (thisValue) => {

            if(fieldType === 'number') {
                return parseInt(thisValue);
            } else {
                return thisValue;
            }
        });
    }

    const getOptionsForPBI = (fieldDescr, fields) => {
        let retObj = { reportID: '', datasetID: '' };

        retObj.defaultHeight = DEFAULT_VIZ_HEIGHT;

        //temp fields called pBIReportID and pBIDatasetID

        if(fieldDescr.hasOwnProperty('pBIReportID') && fieldDescr.hasOwnProperty('pBIDatasetID')) {
            retObj.reportID = fieldDescr.pBIReportID;
            retObj.datasetID = fieldDescr.pBIDatasetID;
        }

        if(fieldDescr.hasOwnProperty('pBI_Filter')) {
            //filters for this power bi viz
           
             let filtersToAdd = fieldDescr.pBI_Filter.map( (thisFilter) => {
                return {
                    $schema: "http://powerbi.com/product/schema#basic",
                    target: {
                        table: thisFilter.fields.targetTable,
                        column: thisFilter.fields.targetColumn
                    },
                    filterType: models.FilterType.Basic,
                    operator: thisFilter.fields.operator,
                    values: formatForProperType(replaceFieldRefWithValue(thisFilter.fields.filterValues, fields), thisFilter.fields.typeOfValueExpected ? thisFilter.fields.typeOfValueExpected : null),
                    requireSingleSelection: thisFilter.fields.requireSingleSelection ? thisFilter.fields.requireSingleSelection : false
                }
            });

            retObj.filters = filtersToAdd;

        }

        //check overrides
        if(fieldDescr.hasOwnProperty('overrides')) {
            let functionalOverrides = filterForApplicableOverrides(fieldDescr.overrides, fields, tenant);

            functionalOverrides.forEach( (thisOverride) => {
                if(thisOverride.fields.hasOwnProperty("pBIReportID") && thisOverride.fields.hasOwnProperty("pBIDatasetID")) {
                   
                    retObj.reportID = thisOverride.fields.pBIReportID;
                    retObj.datasetID = thisOverride.fields.pBIDatasetID;
                }

                if(thisOverride.fields.hasOwnProperty("pBI_Override_Filters") && thisOverride.fields.pBI_Override_Filters !== null){
                    //filters for this power bi viz
                    let filtersToAdd = thisOverride.fields.pBI_Override_Filters.map( (thisFilter) => {
                        return {
                            $schema: "http://powerbi.com/product/schema#basic",
                            target: {
                                table: thisFilter.fields.targetTable,
                                column: thisFilter.fields.targetColumn
                            },
                            filterType: models.FilterType.Basic,
                            operator: thisFilter.fields.operator,
                            values: formatForProperType(replaceFieldRefWithValue(thisFilter.fields.filterValues, fields), thisFilter.fields.typeOfValueExpected ? thisFilter.fields.typeOfValueExpected : null),
                            requireSingleSelection: thisFilter.fields.requireSingleSelection ? thisFilter.fields.requireSingleSelection : false
                        }
                    });
        
                    retObj.filters = filtersToAdd;
                }
            });
        }

        return retObj;
    }

    //first get the right branch of the fieldsDescr
    const getDescriptionItem = (navArray, infoID) => {
        for(let i=0; i < navArray.length; i++) {
            if(navArray[i].subNav) {
                for(let j=0; j < navArray[i].subNav.length; j++){
                    if(navArray[i].subNav[j].urlForField && 
                        navArray[i].subNav[j].urlForField.toLowerCase() === infoID.toLowerCase()) {
                            return navArray[i].subNav[j];
                    }
                }
            }
        }

        //no description items with urlForField that match the infoID
        return undefined;
    }

    //because apps can vary, we'll have this function
    const showNewPBI = (fieldDescr, fields) => {
        return CONSTANTS.NEW_PBI_APPS.indexOf(fields.properties?.referenceName?.toLowerCase()) > -1 ||
                fieldDescr.fieldAPIName.toLowerCase() === 'pyramidape' || CONSTANTS.NEW_PBI_APPS.indexOf(fields.fields?.name?.toLowerCase()) > -1 ;
    }

    const sortPageItemsByOrderDisplayed = (listToSort) => {
        return listToSort.sort( (item1, item2) => {
            let compitem1 = item1.orderDisplayed || 0;
            let compitem2 = item2.orderDisplayed || 0;
            return parseInt(compitem1) - parseInt(compitem2);
        });
    }

    let fieldDescriptionItems = getDescriptionItem(fieldsDescr, infoID);
    fieldDescriptionItems.pageItems = filterOutPageItemsThatHaveNoContent(sortPageItemsByOrderDisplayed(fieldDescriptionItems.pageItems), fields.fields);


    return (
        fieldDescriptionItems && fields && !loading ?
            <>
                {
                    fieldDescriptionItems.pageItems.map((thisItem, index) => {
                        return (
                            <div className="company-field-display" key={index}>
                                {
                                    //sort of hacky to specify the actual field name - TODO: come up with a better scheme for getting rid of headers for sources
                                    thisItem.itemTitle && thisItem.itemTitle !== '' && thisItem.fieldAPIName !== 'sourcesforintroduction' && thisItem.itemTitle !== 'Capability Analysis By Company' && thisItem.itemTitle !== 'Addressable Population Estimator' ?
                                        <h3>{thisItem.itemTitle}</h3>
                                        : null
                                }
                                {
                                    thisItem.fieldType === 'PYRAMID' && fields.fields[thisItem.fieldAPIName] ?

                                        <div className="company-field-pyramid" style={{ minHeight: thisItem.customPyramidHeight ? thisItem.customPyramidHeight + 'px' : DEFAULT_PY_HEIGHT }}>

                                            <PyramidFieldVisual
                                                embedOptions={getEmbedOptionsForItem(thisItem, fields, tenant)}
                                                initWidth={overridePyramidWidth ? overridePyramidWidth : thisItem.customPyramidWidth ? thisItem.customPyramidWidth + '%' : DEFAULT_PY_WIDTH}
                                                initHeight={overridePyramidHeight ? overridePyramidHeight : thisItem.customPyramidHeight ? thisItem.customPyramidHeight + 'px' : DEFAULT_PY_HEIGHT} />

                                        </div>
                                        :

                                        thisItem.fieldType === 'PBI' ?

                                            showNewPBI(thisItem, fields) ?

                                                    <PBIEmbedClassDriven className='field-display-pbi'
                                                    //getOptionsForPBI returns the reportID and the datasetID  
                                                    {...getOptionsForPBI(thisItem, fields)} />

                                                :
                                            
                                                <PBIEmbeddedViz
                                                    //getOptionsForPBI returns the reportID and the datasetID  
                                                    {...getOptionsForPBI(thisItem, fields)} />


                                            :

                                            thisItem.fieldAPIName === 'showNewsApp' ?
                                                <CountryNewsWrapper cntryName={isForCountry.country}>
                                                    <BasicElasticEngine
                                                        engineToUse={'ce-articles'}
                                                        filterItems={[{ field: 'post_region_countries', value: isForCountry ? isForCountry.country : '' }]}
                                                        paginationsize={25}
                                                        formatForNewsList={ircUtils.formatForNewsList} />
                                                </CountryNewsWrapper>
                                                :

                                                thisItem.fieldAPIName === 'companyLeadershipProfiles' || thisItem.fieldAPIName === 'executives' ?
                                                    <LeadershipListDisplay
                                                        company={fields}
                                                        fieldName={thisItem.fieldAPIName} />
                                                    :

                                                    levelThreeNav && levelThreeNav.contentID ?

                                                        <CompareCompTabs
                                                            analysisRec={levelThreeNav} />
                                                        :

                                                        thisItem.fieldAPIName === 'keyEvents' ?
                                                            <KeyEventsDisplay entityToUse={fields} />

                                                            :

                                                            thisItem.fieldAPIName === 'news' ?
                                                                <FilteredPagedNewsPage
                                                                    query={convertNewsListToString(fields.fields.tags)}
                                                                    limitFields={true} />
                                                                :

                                                                <article dangerouslySetInnerHTML={utils.getHTML(fields.fields[thisItem.fieldAPIName])} />
                                }
                            </div>
                        )
                    })
                }

            </>
            : <ListSkeletonLoader preview={false} />
    )
}

export default FieldDisplay
