import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TenantAppBox from './TenantAppBox';

// import '../styles/main.scss';

function AppsCollection({ tenant, className, token, showNav, windowSize, showFakes=false }) {
    
    const findNumColumnsToDisplay = (show, size) => {
        let colToShow = 6;

        if(show === true && size > 1400) {
            colToShow = 4;
        }
        if (show === true && size <1050) {
            colToShow = 12;
        }
        if (show === false && size > 768) {
            colToShow = 6;
        }
        if (show === false && size > 1200) {
            colToShow = 4;
        } 
        //Leaving for now --- We will be moving to showing 4 apps for larger screens
        // if (show === false && size > 1400) {
        //     colToShow = 3;
        // }                 
        return colToShow;
    }

    if(showFakes) { 
        // replace normal apps with a full suite of lorem ipsums
        tenant.tenant.fields.apps = Array(9).fill().map((v,i)=> {
            return (
                {
                    "fields": {
                        "apptype": "",
                        "appname": "Lorem Ipsum",
                        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus ut nunc tincidunt ultricies. Integer nec justo eu justo ultricies posuere. Phasellus id nulla eu nisl scelerisque ultricies. Sed vitae scelerisque purus. Nullam nec purus ut nunc tincidunt ultricies. Integer nec justo eu justo ultricies posuere. Phasellus id nulla eu nisl scelerisque ultricies. Sed vitae scelerisque purus.",
                        "previewImage": {
                            "url": "https://picsum.photos/200"
                        },
                        "appurl": "https://www.google.com",
                        "ctatext": "Learn More"
                    }
            }); 
        });
        
    }

    return (
        token && tenant.tenant.fields.apps ?
            <Row className={"apps-list-container " + className ? className : ''}>
                {
                    tenant.tenant.fields.apps.map((thisApp, idx) => {
                        return (
                            <Col md={12} lg={findNumColumnsToDisplay(showNav, windowSize)} xl={findNumColumnsToDisplay(showNav, windowSize)} className="card-col" >
                                <TenantAppBox
                                    key={idx}
                                    tenantApp={thisApp}
                                    tenant={tenant}
                                    className=""
                                    preview={true}
                                    token={token} />
                            </Col>
                        )
                    })
                }
            </Row>
            : null
    )
}

export default AppsCollection
